import React , {useState} from 'react'
import SuivantButton from '../buttonComponent/Button'
import Swal from 'sweetalert2'
import Select from "../selectComponent/select";
import './informationStyle.css'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RadioGroup from '../radioComponent/radio'
import Modal from '../modalComponent/modal'
import {useAuth} from '../../context/contextProvider'
import { navigate } from 'gatsby-link'
import axios from 'axios'
import { InserData } from '../../firebase/insertData'

const buttonStyle = {height : "50px",background : "#FBCE07", color: " #000000",fontSize: "25px",fontFamily: "Poppins",
}
function Information() {
    const auth = useAuth()
    const [open, setopen] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [info, setinfo] = useState({
        FirsName : '',
        LastName : '',
        Email : '',
        Birthday : '',
        Marque : '',
        Model : '',
        Mileage : '',
        Phone : auth.globalinfo.mobileNumber,
        Huile : auth.globalinfo.productName,
        Status : auth.globalinfo.clientStatus,
        Gift : auth.globalinfo.giftName,
        Profil : '',
        type: ''
    })
    const getinfo = () => {
        setopen(!open)
        Swal.fire({
            icon: 'success',
            title: 'Merci de votre confiance',
            confirmButtonColor : '#dd1d21'
          }).then((result) => {
            if (result.isConfirmed)
                auth.setstatus(0)
                auth.setglobalinfo({...auth.globalinfo, clientStatus : 'old'})
                navigate('/')
          })
    }
    const hidden = () => {
        setopen(!open)
    }
    const FirsnameChange = (event) => {
        setinfo({...info, FirsName : event.target.value})
    }
    const LastNameChange = (event) => {
        setinfo({...info, LastName : event.target.value})
    }
    const EmailChange = (event) => {
        setinfo({...info, Email : event.target.value})
    }
    const MarqueChange = (event) => {
        setinfo({...info, Marque : event.target.value})
    }
    const ModelChange = (event) => {
        setinfo({...info, Model : event.target.value})
    }
    const MileageChange = (event) => {
        setinfo({...info, Mileage : event.target.value})
    }
    const alertName = (event) => Swal.fire({
        icon: 'error',
        title: 'Erreur...',
        text: event,
        confirmButtonColor : '#dd1d21'
    })
    const onChangeSelect = e => {
        const {name, value} = e.target;
        setinfo({...info, [name] : value});
    }
     function Success() {
        let eighteenYearsAgo = moment().subtract(18, "years");
        let Birthday = selectedDate
        const regex = /^[a-zA-Z0-9+\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
         if (info.LastName === '')
            alertName('Veuillez entrer un Nom valide')
        else if (info.FirsName === '')
            alertName('Veuillez entrer un prénom valide')
        else if (!eighteenYearsAgo.isAfter(Birthday))
            alertName('Veuillez entrer une Date de naissance valide')
        else if (info.Marque === '')
            alertName('Veuillez entrer un Marque de voituree valide')
        else if (info.Model === '')
            alertName('Veuillez entrer un Modèle de voiture valide')
        else if (info.Mileage === ' ' || !Number(info.Mileage))
            alertName('Veuillez entrer un Kilométrage actuel valide ')
        else if (!regex.test(info.Email))
            alertName('Veuillez entrer un Email valide')
        else
        {
            setinfo({...info, Birthday : moment(Birthday).format('DD-MM-YYYY')})
            InserData(info, auth.stationInfo)
            const url = "https://api.vivofidelite.dialtechnologies.net/Msg"
            const config = {
                    Phone : info.Phone,
                    Km: `${Number(info.Mileage) + Number(auth.globalinfo.oilsKm)}`,
            } 
            axios.post(url, config).then(res => {
            }).catch(e => {
            })
            setopen(!open)
        }
    }
    return (
        <div className="information">
            <div className="informationTitle">
                <h1>Votre Profil</h1>
                <p>Renseignez vos informations</p>
            </div>
            <div className="FormDiv">
                <form className="formStyle">
                        <RadioGroup></RadioGroup>
                        <input type="text" placeholder="Nom" onChange={LastNameChange} />
                        <input type="text" placeholder="Prénom" onChange={FirsnameChange} />
                        <input type='text' value={auth.globalinfo.mobileNumber} />
                        <DatePicker selected={selectedDate} onChange={(date) => {setinfo({...info, Birthday : moment(date).format('DD-MM-YYYY')}); setSelectedDate(date)}} dateFormat='dd-MM-yyyy' isClearable placeholderText='Date de naissance' showYearDropdown scrollableMonthYearDropdown/>
                        <input type="text" placeholder="Marque de voiture" onChange={MarqueChange}/>
                        <input type="text" placeholder="Modèle de voiture" onChange={ModelChange}/>
                        <input type="text" placeholder="Kilométrage actuel" onChange={MileageChange}/>
                        <input type="email" placeholder="Email" onChange={EmailChange}/>
                        <Select 
                        onChangeSelect={onChangeSelect}
                        name="Profil"
                        data={["Profil de l’automobiliste", "Particulier", "Taxi", "Véhicule utilitaire"]}/>
                        <Select 
                        onChangeSelect={onChangeSelect}
                        name="type"
                        data={["Type de voiture","Diesel", "Essence", "Hybrid"]}/>
                        <SuivantButton name='Suivant' Theme={buttonStyle} verify={() => Success()}/>
                </form>
            </div>
            <Modal show={open} hidden={hidden} getinfo={getinfo}/>
        </div>
    )
}
export default Information