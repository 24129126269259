import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './progress.css'

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" marginTop="40px" textAlign="center">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div"  style={{fontSize : "15px"}} color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
//   const [progress, setProgress] = React.useState(10);
  return <CircularProgressWithLabel style={{width : '60px', height: '60px'}} value={props.onProgress + 19 > 100 ? 100 : props.onProgress + 19} />;
}