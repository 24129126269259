import * as React from "react";

function GiftFillIcon(props) {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="63px"
      width="63px"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M3 2.5a2.5 2.5 0 015 0 2.5 2.5 0 015 0v.006c0 .07 0 .27-.038.494H15a1 1 0 011 1v1a1 1 0 01-1 1H1a1 1 0 01-1-1V4a1 1 0 011-1h2.038A2.968 2.968 0 013 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 10-3 0c0 .085.002.274.045.43a.522.522 0 00.023.07zM9 3h2.932a.56.56 0 00.023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 00-3 0V3z"
      />
      <path d="M15 7v7.5a1.5 1.5 0 01-1.5 1.5H9V7h6zM2.5 16A1.5 1.5 0 011 14.5V7h6v9H2.5z" />
    </svg>
  );
}

export default GiftFillIcon;