import * as React from "react";

function CloseOIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" style={{position : "absolute", right : 0, margin : "20px", width : "30px", height : "30px", cursor : "pointer"}} {...props}>
      <path
        fill="currentColor"
        d="M16.34 9.322a1 1 0 10-1.364-1.463l-2.926 2.728L9.322 7.66A1 1 0 007.86 9.024l2.728 2.926-2.927 2.728a1 1 0 101.364 1.462l2.926-2.727 2.728 2.926a1 1 0 101.462-1.363l-2.727-2.926 2.926-2.728z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 110-18 9 9 0 010 18z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default CloseOIcon;
