import React , {useState}from 'react';
import Radio from '@material-ui/core/Radio';
import './radioStyle.css'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioButtonsGroup(props) {
  const [value, setValue] = useState('mlle');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl className="fieldset" component="fieldset">
      <RadioGroup className='radioGroup' aria-label="gender" name="gender1" value={value}  onChange={handleChange}>
      <FormLabel component="legend" style={{color : '#FFFFFF'}}>Civilité : </FormLabel>
      <div className='radioChecked'>
        <FormControlLabel value="m" control={<Radio />} label="M" />
        <FormControlLabel value="mme" control={<Radio />} label="Mme" />
      </div>
      </RadioGroup>
    </FormControl>
  );
}