import React from "react";
import './select.css';

const Select = ({data, onChangeSelect, name}) => {
    return (
        <select className="select" name={name} onChange={onChangeSelect}>
            {
                data?.map((element, index) =>(
                    <option disabled={index === 0} value={element} key={index}>{element}</option>
                ))
            }
        </select>

    )
}

export default Select;