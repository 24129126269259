import React, {useState, useEffect} from 'react';
import './modalStyle.css'
import { makeStyles } from '@material-ui/core/styles';
import SendButton from '../buttonComponent/Button'
import SmileIcon from '../iconsComponent/smile'
import DizzyIcon from '../iconsComponent/dizzy'
import ExpressionlessIcon from '../iconsComponent/expressionless'
import VerryHappyIcon from '../iconsComponent/veryhappy'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CloseOIcon from '../iconsComponent/close'
import Fade from '@material-ui/core/Fade';
import GiftFillIcon from '../iconsComponent/gifticon'
import {useAuth} from '../../context/contextProvider'
import { useMediaQuery } from 'react-responsive'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign : "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    position: 'relative',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "& h2": {
        color : "#DD1D21 ",
    },
    "& h1" : {
        color : "#666666",
        fontSize : "2.125rem",
        margin : "10px"
    },
    "& div" : {
        margin : "30px 10px"
    }
  },
  feedback : {
    display: "flex",
    flexDirection: "column",
    justifyDontent: "center",
    alignItems: "center",
  },
}));
var buttonStyle = {
  height : "55px", background : "#DD1D21", color: "#FBFBFB" ,width : "50%",fontSize : "0.9375rem", fontFamily: "Poppins",
}
const ButtonSuivant = {
  height : "35px", background : "#DD1D21", color: "#FBFBFB" ,fontSize : "0.9375rem", fontFamily: "Poppins", float : 'right'
}
const Icons = [
  {
    icon : DizzyIcon,
    name : "dizzy"
  },
  {
    icon : ExpressionlessIcon,
    name : "expressionless"
  },
  {
    icon : SmileIcon,
    name : "smile"
  },
  {
    icon : VerryHappyIcon,
    name : "verryhappy"
  },
]
function TransitionsModal(props) {
  const auth = useAuth()
  const size = useMediaQuery({ maxDeviceWidth: 768 })
  const classes = useStyles();
  const [status, setStatus] = useState(0)
  const [open, setOpen] = useState(false);
  const [showGift, setShowGift] = useState(false)
  const [comment, setcomment] = useState('')
  const [selected, setSelected] = useState({
    element : 0,
    index : 0,
  })
  const [feedback, setfeedback] = useState([
  {
    name : 'Accueil client',
    icon : '',
    color : [
      { 
        index : 0,
        color : '#CBCBCB'
      }
    ]
  },
  {
    name : 'Service vidange ',
    icon : '',
    color : [
      { 
        index : 0,
        color : '#CBCBCB'
      }
    ]
  },
  {
    name : 'Programme de fidélité',
    icon : '',
    color : [
      { 
        index : 0,
        color : '#CBCBCB'
      }
    ]
  }
  ])
  const CommentChange = (e) => {
    setcomment(e.target.value)
  }
  const changeButtonSize = () => 
  {
    if (size)
      buttonStyle.width = "80%"
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function insertFeedback(name, elm, i) {
    let modif = feedback
    modif[elm].icon = name
    let colorStyle = ''
    if (i === 0)
      colorStyle = '#FF0000'
    else if (i === 1)
      colorStyle = '#FFFF00'
    else if (i === 2)
      colorStyle = '#FFA500'
    else if (i === 3) 
      colorStyle = '#008000'
    modif[elm].color[0].color = colorStyle
    modif[elm].color[0].index = i
    setfeedback(modif)
  }
  useEffect(() => {
    changeButtonSize()
  }, [])
  return (
      <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.show}
        onClose={props.hidden}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.show}>
            <div className={classes.paper}>
              <CloseOIcon onClick={props.hidden}/>
            {props.steps ?
                <>
                <div>
                    <h1>Récupérez votre carte de fidélité</h1>
                    <div>
                      <h2 style={{fontSize : '1.2rem'}}>Devenez membre du programme Shell Club Fidélité et profitez de ses nombreux avantages !</h2>
                      <div>
                        <p>
                          Merci de récupérer votre carte Shell Club Fidélité auprès du pompiste.
                          </p>
                          <p>
                            Un SMS d’activation vous sera envoyé.
                          </p>
                      </div>
                      <SendButton name='Suivant' Theme={ButtonSuivant} verify={props.hidden}/>
                    </div>
                  </div>
                </>
              :
                <div className={classes.feedback}>
                  {status === 0 && !showGift ?
                    <div>
                      <GiftFillIcon/>
                      <div>
                      <h2>FÉLICITATIONS </h2>
                      <p>Vous avez gagné un <mask onClick={() => setOpen(!open)} style={{cursor : 'pointer'}}>{auth.globalinfo?.giftName}</mask></p>
                      <p>Récupérez votre gain auprès du pompiste.</p>
                      <h3>MERCI DE VOTRE PARTICIPATION</h3>
                      </div>
                      <SendButton name= "VOTRE AVIS NOUS INTERESSE" Theme={buttonStyle} verify={() => setStatus(1)}/>
                    </div>
                  : status === 1 ?
                  <div className="feedback">
                    <h1 style={{fontSize : "1.9rem"}}>Votre avis nous intéresse ! </h1>
                    {
                      feedback.map((title, elm) => (
                        <div className="feedbackType">
                        <div className="paraStyle" key={elm}>
                          <p >{title.name}</p>
                        </div>
                        <div className="feedbackIcons">
                            {
                                Icons.map((icon,i) => (
                                <>
                                <icon.icon  key={i} onClick = {() => {setSelected({...selected, element : elm, index : i}); insertFeedback(icon.name, elm, i)}} color={i === title.color[0].index ? title.color[0].color : '#CBCBCB'}/>
                                </>
                              ))
                            }
                        </div>
                    </div>
                    ))}
                    <form className="modalForm">
                      <textarea
                        onChange = {CommentChange}
                        className="modalTextarea"
                        placeholder="Commentaire"
                        >
                      </textarea>
                      <SendButton name= "Envoyer" Theme={buttonStyle} verify={() => props.getinfo()}/>
                    </form>
                  </div> : ''
                }
                </div>
              }
            </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <CloseOIcon onClick={handleClose}/>
          <div className='ModalGift'>
            <img src={auth.globalinfo?.giftUrl}/>
            <h1>{auth.globalinfo?.giftName}</h1>
            <p>{auth.globalinfo?.giftDescription}</p>
            </div>
          </div>
        </Fade>
      </Modal>
      </div>
  );
}
export default TransitionsModal