import { navigate } from 'gatsby-link'
import './verifycodeStyle.css'
import React , {useState} from 'react'
import axios from 'axios'
import VerifyButton from '../buttonComponent/Button'
import {useAuth} from '../../context/contextProvider'


const buttonStyle = {
    height : "50px", background : "#DD1D21", color: "#FBFBFB" ,width : "100px",fontSize : "1.1rem", fontFamily: "Poppins"
}
const markStyle = {
    backgroundColor : "snow",
    color : "#DD1D21"
}
function Verifycode() {
    const auth = useAuth();
    const [numberPhone, setNumberPhone] = useState('')
    const [count, setCount] = useState(0)
    const [error, seterror] = useState(false)
    const InputChange = (e) => {
        setNumberPhone(e.target.value)
        seterror(false)
    }
    function VerifyCode () {
        const url = "https://api.vivofidelite.dialtechnologies.net/getCard"
        const mobileNumber = {
            'mobileNumber' : numberPhone
        }
        axios.post(url, mobileNumber).then(res => {
            // console.log(res)
            if (res.data.status === 'Ok')
            {
                auth.setglobalinfo({...auth.globalinfo, mobileNumber : numberPhone})
                navigate('/gift')
            }
            else if (res.data.status === 'Ko')
            {
                setCount((old) => old + 1)
                seterror(!error)
                if (count === 2)
                {
                    setTimeout (function() {navigate('/');} , 2000)
                }
            }
        }).catch(e => {
            // console.log(e)
        })
    }
    return (
        <div>
            <div>
                <h1>Participez au jeu virtuel</h1>
                <p >Renseignez le numéro de votre carte de fidélité pour participer au jeu virtuel <mark style={markStyle}>"GRATTEZ-GAGNEZ"</mark>.</p>
            </div>
            <div>
                <input
                    type ="text"
                    placeholder = "Votre numéro de téléphone"
                    className="inputStyle"
                    onChange = {InputChange}
                />
                <VerifyButton 
                    name="Vérifier" 
                    Theme={buttonStyle} 
                    verify={() => VerifyCode()}
                />
                {
                     count === 3 ?
                     <h3>Désolé, vous avez atteint le nombre maximum de tentatives</h3>
                     :
                    error ?
                    <h3 className="ErrorMsg">
                        <span>Votre numéro n'est pas valide.</span>
                        <span>Merci de récupérer votre carte Shell Club Fidélité auprès du pompiste.</span>
                        <span>Un SMS d’activation vous sera envoyé.</span>
                    </h3>
                    :
                    ''
                }
            </div>
        </div>
    )
}

export default Verifycode