import React , {useState, useEffect} from 'react'
import './scratchStyle.css'
import scr500 from "../../images/scr500.png";
import scr380 from "../../images/scr380.png";
import scr300 from "../../images/scr300.png";
import ScratchCard from './scratchEffect'
import Progress from  '../progressComponent/progress'
import { useMediaQuery } from 'react-responsive'
import {useAuth} from '../../context/contextProvider'

function Scratch() {
    const auth = useAuth()
    const [loader, setloader] = useState(0)
    const [MediaScreen, setMediaScreen] = useState({
        width : 0,
        height : 0,
        cover : scr300,
    })
    const large = useMediaQuery({ minDeviceWidth: 768 })
    const medium = useMediaQuery({ maxDeviceWidth: 414 })
    const small = useMediaQuery({ maxDeviceWidth: 375 })
    useEffect(() => {
       setMediaScreen({
           ...MediaScreen,
           width : (small ? 300 : '' ) || (medium ? 380 : '' ) || (large ? 500 : ''),
           height : (small ? 150 : '' ) || (medium ? 200 : '' ) || (large ? 240 : ''),
           cover : (small ? scr300 : '' ) || (medium ? scr380 : '' ) || (large ? scr500 : ''),
       })
    }, [])
     if (MediaScreen.width === 0 ) {return <></>}
    return (
        <div>
            <div className="scratchTitle">
                <h1>Grattez Gagnez</h1>
                <p>Grattez pour découvrir <mark className="markStyle">votre cadeau</mark>.</p>
                <ScratchCard
                 width={MediaScreen.width} 
                 height={MediaScreen.height}
                 cover={MediaScreen.cover}
                 Finish = {() => auth.setstatus(3)}
                 Progress = {(loading) => setloader(loading)}
                >
			    <h1 className="getNumber">1651613335</h1>
                </ScratchCard>
                {loader === 0  ? '' : <div style={{textAlign : "center", width : MediaScreen.width}}><Progress onProgress={loader}/></div>}
            </div>
        </div>
    )
}
export default Scratch