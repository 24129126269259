import React  from 'react'
import '../styles/verify.css'
import VerifyCode from '../components/verifyComponent/verifycode'
import Information from '../components/informationComponent/information'
import Status from '../components/statusComponent/status'
import Scratch from '../components/scratchComponent/scratch'
import Gifticon from '../components/imageComponent/gift'
import {useAuth} from '../context/contextProvider'

function Verify() {
    const auth = useAuth()
    return (
        <div className='validation'>
            <div className='verifyDiv'>
                {auth?.status === 1 ? <VerifyCode /> :""}
                { auth?.status === 3  ? <Information/> : ''}
                { auth?.status === 0 ? <Status /> : ''}
                { auth?.status === 2  ? <Scratch/> : ''}
            </div>
            <div className="giftIcon">
                <Gifticon />
            </div>
        </div>
    )
}
export default Verify
