import React , {useState} from 'react'
import './statusStyle.css'
import Button from '../buttonComponent/Button'
import Modal from '../modalComponent/modal'
import {useAuth} from '../../context/contextProvider'

const buttonStyle = {
    height : "40px", background : "#DD1D21", borderRadius : "10px" ,color: "#FBFBFB" ,width : "140px",fontSize : "1.1rem", fontFamily: "Poppins"
}
function Status() {
    const auth = useAuth()
    const [open, setopen] = useState(false)
    const Hidden = () => {
        setopen(!open)
    }
    const handelCheck = () => {
        setopen(!open)
        auth.setglobalinfo({...auth.globalinfo, clientStatus : 'new'})
    }
    return (
        <div className="statusDiv">
             <div className="status">
             <h1 className='TitleStyle'>Êtes-vous membre de Shell Club Fidélité ?</h1>
                <Button verify={() => auth.setstatus(1)} name="Oui" Theme={buttonStyle}/>
                <Button  verify={() => handelCheck()} name="Non" Theme={buttonStyle}/>
            </div>
            <Modal steps show={open} hidden={Hidden}/>
        </div>
    )
}


export default Status